import Vue from 'vue'
import App from './App.vue'
import router from './router'
import BaiduMap from 'vue-baidu-map'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(BaiduMap, {
  ak: 'tEUov56r9UnQ9sr9A1iisAcvBBQBEt20'
})
Vue.config.productionTip = false
import VueI18n from 'vue-i18n'
import messages from './message.js'
import moment from 'moment';

Vue.use(VueI18n)
Vue.use(ElementUI);

Vue.prototype.$moment = moment;

const i18n = new VueI18n({
  locale: 'zh', // 默认语言为中文
  messages
})

// 用法：1.{{ item.time | format("YYYY-MM-DD HH:mm:ss") }}
// 用法：2. this.$moment(this.time).format("YYYY-MM-DD HH:mm:ss");
Vue.filter('format', function (input, fmtstring) {
  // 使用momentjs这个日期格式化类库实现日期的格式化功能
  return moment(input).format(fmtstring);
});

new Vue({
  i18n,
  router,
  render: h => h(App)
}).$mount('#app')