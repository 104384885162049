<template>
  <div id="app">
    <div class="top">
      <div class="logo">
        <img src="./assets/国播官网/资源 22mdpi.png" alt="" />
      </div>
      <div class="nav">
        <div
          :class="{ navActive: navActive == index }"
          @click="selectNav(index)"
          :key="index"
          v-for="(item, index) in navList"
        >
          {{ $t(item) }}
        </div>
        <!-- <select v-model="currentLanguage" @change="changeLanguage">
          <option value="zh">中文</option>
          <option value="en">English</option>
          <option value="Japanese">Japanese</option>
        </select> -->

        <!-- <div>产品介绍</div>
        <div>技术支持</div>
        <div>商务合作</div>
        <div>关于我们</div> -->
      </div>
      <el-select
        v-model="currentLanguage"
        placeholder="请选择语言"
        @change="changeLanguage"
      >
        <el-option label="中文" value="zh"> </el-option>
        <el-option label="English" value="en"> </el-option>
        <el-option label="日本語" value="Japanese"> </el-option>
        <el-option label="韓国語" value="SouthKorea"> </el-option>
        <el-option label="ภาษาไทย" value="Thai"> </el-option>
        <el-option label="ang Pilipinas" value="Filipino"> </el-option>
        <el-option label="Русский язык" value="Russian"> </el-option>
        <el-option label="Français" value="French"> </el-option>
      </el-select>
    </div>
    <router-view />
    <div class="footer">
      <!-- <img src="../assets/国播官网/LOGO(白).png" alt="" /> -->
      <div class="left">
        <div class="name">{{ $t("杭州国播优选科技有限公司") }}</div>
        <div class="address flex">
          <div class="logo">
            <img src="./assets/国播官网/底部地址@3x.png" alt="" />
          </div>
          <div class="txt">{{ $t("地址") }}</div>
        </div>
        <div class="qq flex">
          <div class="logo">
            <img src="./assets/国播官网/底部邮箱@3x.png" alt="" />
          </div>
          <span>1046754672@qq.com</span>
        </div>
        <div class="phone flex">
          <div class="logo">
            <img src="./assets/国播官网/底部手机@3x.png" alt="" />
          </div>
          <span>0571-85080190 /15381025709</span>
        </div>
        <div class="copyRight">
          Copyright © 2023 {{ $t("杭州国播优选科技有限公司") }} All Rights
          Reserved. 浙ICP备2023014332号-1
        </div>
      </div>
      <div class="erWeiMa">
        <div class="img">
          <img src="./assets/二维码.jpg" alt="" />
        </div>
        <div class="txt">{{ $t("国播优选官方微信号") }}</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      navActive: 0,
      navList: ["首页", "产品介绍", "技术支持", "商务合作", "关于我们","产品下载"],
      currentLanguage: "language",
    };
  },
  mounted() {
    this.$router.push("/");
  },
  methods: {
    changeLanguage() {
      this.$i18n.locale = this.currentLanguage;
    },
    selectNav(index) {
      if (this.navActive == index) {
        return false;
      }
      this.navActive = index;
      if (index == 0) {
        this.$router.push("/");
      } else if (index == 1) {
        this.$router.push("/productInfo");
      } else if (index == 2) {
        this.$router.push("/jiShu");
      } else if (index == 3) {
        this.$router.push("/cooperateUser");
      } else if (index == 4) {
        this.$router.push("/AboutView");
      }else if(index == 5){
        this.$router.push("/productDown");
      }
    },
  },
};
</script>
<style lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  font-size: 20px;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  // width: calc(100px / 375 * 100vw) !important;
}
a {
  text-decoration: none; /* 去掉下划线 */
  color: inherit; /* 继承父元素的颜色 */
  cursor: pointer; /* 鼠标悬停时显示指针 */
}
nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
<style lang="less">
.flex {
  display: flex;
  align-items: center;
}
.top {
  width: auto;
  display: flex;
  padding: 24px 90px;

  .logo {
    width: 200px;
    // height: 47px;

    img {
      width: 100%;
    }
  }

  .nav {
    margin-left: 109px;
    // height: 47px;
    // width: 488px;
    // justify-content: space-between;
    display: flex;
    line-height: 47px;
    font-family: Microsoft YaHei;
    font-size: 30px;
    font-weight: 400;
    flex-wrap: wrap;
    div {
      cursor: pointer;
      margin: 0 30px;
    }
  }

  .navActive {
    font-family: Microsoft YaHei;
    font-size: 30px;
    font-weight: 700;
    text-align: left;
    color: #1053f3;
    border-bottom: 2px solid #1053f3;
  }
  .link{
    position: absolute;
    right: 50px;
    color: #2c3e50;
    .el-link--inner{
        font-size: 20px;
        margin-top: 5px;
        font-weight: 700;
    }
    .el-link.el-link--default:hover {
      color: #1053f3;
    }
  }
}
.footer {
  background: url("./assets/国播官网/Group 1171274781@3x.png") no-repeat;
  background-size: 100% 100%;
  width: auto;
  height: 398px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  position: relative;

  > img {
    position: absolute;
    left: 40px;

    z-index: 0;
  }

  .left {
    height: 100%;
    z-index: 10;
    padding: 42px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: flex-start;
    .logo {
      width: 46px;
      height: 46px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #fff;
      border-radius: 50%;
      margin-right: 14px;
      img {
        width: 60%;
      }
    }
    .qq {
      background: url("./assets/国播官网/底部邮箱@3x.png");
    }
    .name {
      font-size: 56px;
      font-weight: 700;
    }
  }

  .erWeiMa {
    padding: 42px;

    .img {
      width: 250px;
      height: 250px;
      margin-bottom: 10px;

      img {
        width: 100%;
      }
    }
  }
}
</style>
